import { useAuth } from '@airelogic/form-management/common';
import { Can } from '@airelogic/form-management/common/app-context';
import { When } from '@airelogic/form-management/components';
import PersonIcon from '@mui/icons-material/Person';
import {
  AppBar,
  Button,
  ButtonProps,
  Container,
  Grid2 as Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
} from '@mui/material';
import { useState } from 'react';
import { NavLinkProps, NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as FormsLogo } from '../AireForms.svg';
import TenantDisplayName from './TenantDisplayName';

const useNavLinkStyles = makeStyles()((theme: Theme) => {
  return {
    link: {
      color: theme.palette.grey.A100,
      textTransform: 'none',
      borderRadius: '10px',
      border: '4px solid',
      borderColor: '#251430',
      '&:hover': {
        color: theme.palette.common.white,
        background: 'none',
      },
      '&.active': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
      fontSize: 'large',
    },
  };
});

const NavLink = (props: Omit<NavLinkProps, 'color' | 'className' | 'style'>) => {
  const { classes } = useNavLinkStyles();

  return (
    <Button
      disableRipple
      disableFocusRipple
      disableElevation
      disableTouchRipple
      className={classes.link}
      component={RouterLink}
      {...props}
    >
      {props.children}
    </Button>
  );
};

const ExternalNavLink = (props: Pick<ButtonProps<'a'>, 'children' | 'href'>) => {
  const { classes } = useNavLinkStyles();

  return (
    <Button
      disableRipple
      disableFocusRipple
      disableElevation
      disableTouchRipple
      component={'a'}
      className={classes.link}
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </Button>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  toolBar: {
    flexGrow: 1,
    paddingLeft: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white',
    paddingLeft: 0,
  },
  menuIcon: {
    fontSize: '40px',
  },
  accountIcon: {
    fontSize: '25px',
  },
  menuLogo: {
    height: 35,
    marginRight: '5px',
  },
  accountButton: {
    color: 'white',
    background: '#33444E',
    margin: '5px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '&:active': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
}));

const BASE_PATH = '/formbuilding';

// TODO: Extract these paths
const usePaths = (basePath?: string) => ({
  formBuilding: basePath,
  buildingBlocks: `${basePath}/buildingblocks`,
  forms: `${basePath}/forms`,
  referenceData: `${basePath}/referencedata`,
});

const NavMenu = () => {
  const { classes } = useStyles();
  const paths = usePaths(BASE_PATH);

  const { logout, user } = useAuth();

  const onClickLogout = async () => {
    await logout();
    handleAccountClose();
  };

  const [anchorAccountEl, setAnchorAccountEl] = useState(null);

  const handleAccountMenu = (event: any) => {
    setAnchorAccountEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorAccountEl(null);
  };

  return (
    <AppBar position="sticky">
      <Container>
        <Toolbar className={classes.toolBar} role="navigation" component="nav">
          <Grid container justifyContent="space-between" alignItems="center" width={'100%'}>
            <Grid size="auto">
              <FormsLogo title="AireForms" className={classes.menuLogo} />
            </Grid>
            <When condition={!!user}>
              <Grid size={'auto'}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid>
                    <Can I="create" a="BuildingBlock">
                      <NavLink to={paths.buildingBlocks}>Building Blocks</NavLink>
                    </Can>
                  </Grid>
                  <Grid>
                    <Can I="preview" a="Form">
                      <NavLink to={paths.forms}>Forms</NavLink>
                    </Can>
                  </Grid>
                  <Grid>
                    <Can I="create" a="ReferenceDataSet">
                      <NavLink to={paths.referenceData}>Reference Data</NavLink>
                    </Can>
                  </Grid>
                  <Grid>
                    <ExternalNavLink href="https://docs.forms.aireinnovate.com/">
                      Docs
                    </ExternalNavLink>
                  </Grid>
                </Grid>
              </Grid>
            </When>
            <Grid size="auto">
              {user && (
                <Grid container alignItems="center" spacing={2}>
                  <Grid>
                    <TenantDisplayName
                      displayName={user.tenantDisplayName}
                      environmentKey={user.tenantEnvironmentKey}
                    />
                  </Grid>
                  <Grid>
                    <IconButton
                      aria-label="My Account"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      size="small"
                      onClick={handleAccountMenu}
                      className={classes.accountButton}
                    >
                      <PersonIcon className={classes.accountIcon} />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorAccountEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      open={Boolean(anchorAccountEl)}
                      onClose={handleAccountClose}
                    >
                      {!!user.manageProfileUrl && (
                        <MenuItem
                          component={Link}
                          href={user.manageProfileUrl}
                          className={classes.link}
                        >
                          Manage Profile
                        </MenuItem>
                      )}
                      <MenuItem onClick={onClickLogout}>Log Out</MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavMenu;
