import { Box, Paper, Typography } from '@mui/material';

const headlineText = "A fatal error has occurred that requires the AireForms team's attention.";
const apologyText = 'We apologise for the inconvenience.';

const ErrorPage = () => {
  return (
    <Paper>
      <Box height="100%" padding="20px" display="flex" flexDirection="column" alignItems="centre">
        <Typography variant="h5" align="center">
          {headlineText}
        </Typography>
        <Typography variant="h6" align="center">
          {apologyText}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ErrorPage;
