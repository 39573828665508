import {
  Box,
  Button,
  Container,
  Divider,
  Grid2 as Grid,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as FormsLogo } from '../AireForms.svg';
import aireInnovateLogo from './AireInnovate.webp';

const useStyles = makeStyles()((theme: Theme) => ({
  background: {
    backgroundSize: 'cover',
    backgroundPosition: 'right center',
    position: 'absolute',
    inset: '0px',
    zIndex: -1,
    '@media screen and (max-width: 1080px)': {
      backgroundImage: 'url(/assets/splash1080p.webp)',
    },
    '@media screen and (min-width: 1081px)': {
      backgroundImage: 'url(/assets/splash1440p.webp)',
    },
    '@media screen and (min-width: 2561px)': {
      backgroundImage: 'url(/assets/splash4k.webp)',
    },
  },
  aireFormsLogo: {
    height: 36,
  },
  paper: {
    backgroundColor: '#24243B',
    color: 'white',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '20px',
  },
  divider: {
    backgroundColor: '#9EB3BF',
    width: '100%',
  },
}));

const LoginPage = () => {
  const history = useHistory();

  const { classes: styles } = useStyles();

  const onLoginClick = () => {
    const params = new URLSearchParams(history.location.search);
    const returnUrl = params.get('returnUrl') ?? '/';
    window.location.assign(`/oidc/login?returnUrl=${encodeURI(returnUrl)}`);
  };

  return (
    <>
      <span className={styles.background} />
      <Container maxWidth="xs">
        <Box display="flex" height="100%" alignItems="center">
          <Paper className={styles.paper} variant="outlined">
            <Grid container spacing={3}>
              <Grid container justifyContent="center" size={12}>
                <Typography component="p" variant="h5">
                  Welcome to
                </Typography>
              </Grid>
              <Grid container justifyContent="center" size={12}>
                <FormsLogo title="AireForms" className={styles.aireFormsLogo} />
              </Grid>
              <Grid container size={12}>
                <Button fullWidth={true} variant="contained" color="primary" onClick={onLoginClick}>
                  Sign in
                </Button>
              </Grid>
              <Grid container size={12}>
                <Divider
                  className={styles.divider}
                  orientation="horizontal"
                  sx={{
                    opacity: '0.6',
                  }}
                />
              </Grid>
              <Grid container justifyContent="center" size={12}>
                <img src={aireInnovateLogo} alt="Aire Innovate logo" />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default LoginPage;
